<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="user === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="user">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="user" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="user" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card
            :user-data="user"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card
            :user-data="user"
          />
        </b-col>        
      </b-row>
      <div class="col-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Courses ({{user.courses.length}})
            </h4>
            <b-button
              v-b-modal.modal-no-animation
              variant="primary"
            >
              Assign Courses
              <feather-icon
                class="mr-25"
                icon="EditIcon"
                size="15"
              />              
            </b-button>
          </div>
          <div class="card-datatable">
            <table class="datatables-ajax table">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Course</th>
                  <th>Code</th>
                  <th>Fee</th>
                  <th>Discount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Course</th>
                  <th>Code</th>
                  <th>Fee</th>
                  <th>Discount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </tfoot>
              <tbody>
                <!-- <tr v-if="!user.courses.length">
                  <td colspan="8">No course(s) assgined </td>
                </tr> -->
                <tr
                  v-for="(course, index) in user.courses"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ course.title }}</td>
                  <td>{{ course.code }}</td>
                  <td>{{ course.fee }}</td>
                  <td>{{ course.discount }}</td>
                  <td>
                    {{ course.type }}
                  </td>
                  <td>{{ course.created_at_formatted }}</td>
                  <td :class="course.isActive ? 'text-success' : 'text-danger'">
                    {{ course.isActive ? 'Active' : 'Not Active' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in pageMeta.links" :key="link.id">
              <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
            </li>
          </ul>
        </nav>             
      </div>
      <b-modal
        id="modal-no-animation"
        content-class="shadow"
        :title="`Assign Courses to ${user.first_name+' '+user.last_name}`"
        centered
        size="lg"
        no-close-on-backdrop
        hide-footer
      >
        <b-form>
          <b-row>
            <b-col lg="12" md="12">
              <b-form-group>
                <b-form-input
                  type="text"
                  name="search"
                  aria-label="Search"
                  v-model="searchString"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>

            <b-col lg="12" md="12">
              <b-form-group
                class="text-primary"
                label="Courses"
              >
                <b-form-checkbox-group
                  name="courses"
                  v-model="details.courses"
                >
                  <b-form-checkbox 
                    v-for="course in courseList" 
                    :key="course.id" 
                    :value="course.id"
                    class="mr-1 mb-1"
                  >
                    {{course.title}}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" class="text-center">
              <b-button variant="primary" size="lg" @click="assignCourses">
                Assign 
                <b-spinner
                  variant="white"
                  small
                  class="ml-1"
                  v-show="loading"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>        
    </template>
  </div>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol, BAlert, BLink, BButton, BCard, BForm,
   BModal, BCardText, BFormGroup, BSpinner,
   BFormCheckboxGroup, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from './TutorViewUserInfoCard.vue'
import UserViewUserPlanCard from './TutorViewUserPlanCard.vue'
import UserViewUserTimelineCard from './TutorViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './TutorViewUserPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BAlert,
    BSpinner,
    BLink,
    BButton,
    BModal,
    BCardText,
    BCard,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormInput,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    InvoiceList,
  },
  data() {
    return {
      user: {},
      transactions: [],
      trans: [],
      url: '',
      links:{},
      pageMeta: {},
      details: {},
      courses: [],
      searchString: '',
      loading: false,
    }
  },
  computed: {
    courseList() {
      return this.courses.filter(course => course.title.includes(this.searchString) || 
        course.title.toLowerCase().includes(this.searchString)
      )
    }
  },
  methods: {
    assignCourses() {
      this.loading = true
      this.details.user_id = this.user.id
      this.$http.post(`${this.$url}/tutor/assign-courses`, this.details)
        .then(response => {
          console.log("categories", response.data)
          if(response.data.status) {
            // this.user.courses.push(response.data.data) 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })  
            .then(() => {
              window.location.reload()
            })          
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },
    getCourses() {
      this.$http.get(`${this.$url}/courses`)
        .then(response => {
          if(response.data.status === true) {
            this.courses = response.data.data.data
          }
        })
    },
    getTransactions() {
      this.$http.get(this.url)
        .then(response => {
          if(response.data.status === true) {
            this.transactions = response.data.data.data
            this.links = response.data.data.links
            this.pageMeta = response.data.data.meta
          }
        })
    },
    paginate(url) {
      this.url = url
      this.getTransactions()
    },        
  },
  created() {
    this.$http.get(`${this.$url}/tutor/${router.currentRoute.params.id}`)
      .then(response => {
        this.user = response.data.data
        this.url = this.$url+`/tutor/courses/${this.user.id}`
      })
      this.getCourses()
  },
}
</script>

<style>

</style>
