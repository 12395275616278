<template>
  <b-card title="Qualifications">
    <app-timeline v-if="userData.qualifications && userData.qualifications.length">
      <!-- <app-timeline-item
        v-for="qualification in userData.qualifications"
        :key="qualification.id"
        variant="success"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ qualification.qualification+' - '+qualification.institution }}</h6>
          <strong class="text-primary">{{ qualification.start_date_formatted+' - '.qualification.end_date_formatted }}</strong>
        </div>
      </app-timeline-item> -->
      <app-timeline-item
        v-for="qualification in userData.qualifications"
        :key="qualification.id"      
        variant="success"
        :title="qualification.qualification"
        :subtitle="qualification.institution"
        :time="qualification.start_date_formatted+' - '.qualification.end_date_formatted"
      />
    </app-timeline>
    <p class="text-center" v-else>No qualification data</p>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
