<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Bio</b-card-title>
      <p v-if="userData.bio">
        {{userData.bio}}
      </p>
      <div
        v-else
        class="text-center"
      >
        <p>No bio details </p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BCardTitle,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
