<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <!-- <h5 class="mb-0">
        Bank Details
      </h5> -->
      <!-- <b-badge variant="light-primary">
        Basic
      </b-badge> -->
      <!-- <small class="text-muted w-100">July 22, 2021</small> -->
    </b-card-header>

    <b-card-body>
      <!-- <ul
        v-if="userData.bank_details"
        class="list-unstyled my-1"
      >
        <li>
          <strong>Transactions:</strong> <span class="align-middle text-success">({{ userData.stats.count }})</span>
        </li>
        <li class="my-25">
          <strong>Amount:</strong> <span class="align-middle text-primary">({{ userData.stats.yearly }})</span>
        </li>
      </ul>
      <div
        v-else
        class="text-center"
      >
        <p>No transaction details </p>
      </div> -->
      <b-button
        v-if="!userData.isActive === 'Not Verified'"
        id="approve"
        type="button"
        ripple
        variant="primary"
        @click="manageAccount($event)"
      >
        Activate
        <b-spinner
          v-show="loading"
          small
          variant="light"
        />
      </b-button>
      <b-button
        v-else
        id="decline"
        type="button"
        ripple
        variant="danger"
        @click="manageAccount($event)"
      >
        Deactivate
        <b-spinner
          v-show="loading"
          small
          variant="light "
        />
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BSpinner,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      details: {},
      option: '',
      loading: false,
    }
  },
  methods: {
    manageAccount(event) {
      this.loading = true
      const details = {
        agent_id: this.userData.agent_id,
        option: event.target.id,
      }
      // console.log('details', details)
      this.$http.post(`${this.$url}/tutor/verify-account`, details)
        .then(response => {
          // console.log('===', response)
          window.location.reload()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
  button {
    cursor: pointer !important;
  }
</style>
